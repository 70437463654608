// import React from "react";
import { memo, useEffect } from "react";
import Image from "next/image";

import Home from "@material-ui/icons/Home";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";

import Carousel from "react-material-ui-carousel";
import { Box, Button, Paper, Typography } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import { withTheme, useTheme } from "@material-ui/core/styles";
import axios from "axios";
import useSWR from "swr";

const fetcher = (url) => axios.get(url).then((res) => res.data);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  main_container: {
    minHeight: "536px",
    height: "536px",
  },
  image_container: {
    height: "100%",
  },
  carousel_img: {
    // height: '400px'
  },
  content: {
    // background: 'red',
    position: "relative",
    height: "100%",
    display: "grid",
    gridTemplateColumns: "100%",
    gridTemplateRows: "90% 10%",
    zIndex: "2",
  },
  subtitle: {
    fontSize: "18px",
  },
  gradient: {
    position: "absolute",
    width: "100%",
    height: "250px",
    bottom: "0",
    // backgroundImage: 'linear-gradient(to top, #111, rgba(0, 0, 0, 0))',
  },
  test: {
    "& svg": {
      width: "1rem",
    },
  },
  placeholder: {
    width: "100%",
    height: "595px",
    background: "#ccc",
  },
}));

const Carrusel = (props) => {
  const { data, error, isValidating } = useSWR("/api/getBanners", fetcher, {
    revalidateOnFocus: false,
    revalidateOnMount: true,
    refreshInterval: 0,
  });
  let banners = [];
  const { desktop } = props;

  // console.log('banners en carrusel',banners);

  const classes = useStyles();
  useEffect(() => {}, [data]);

  if (error) return <div>failed to load {error}</div>;
  if (!data) return <div className={classes.placeholder}></div>;
  //   console.log("BANNERS-------------->>:", data, error);
  //   const data = result.data;
  //   const error = result.error;
  //   if (error == undefined || data == undefined) return null;

  return (
    <Carousel
      autoPlay={false}
      navButtonsAlwaysVisible={false}
      // className={classes.main_container}
      // TODO: Indictor styles are not working, double check if version 2.2 has been released
      // indicators should be orange
      IndicatorIcon={<FiberManualRecordIcon />} // Previous Example
      indicatorIconButtonProps={{
        className: classes.test,
        style: {
          padding: "10px", // 1
          color: "#f44336", // 3
        },
      }}
      activeIndicatorIcomButtonProps={{
        style: {
          backgroundColor: "blue", // 2
          color: "#f00", // 2
        },
      }}
      indicatorContainerProps={{
        style: {
          marginTop: "15px", // 5
          textAligh: "right", // 4
          color: "gray",
        },
      }}
      navButtonsProps={{
        // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
        style: {
          backgroundColor: "#f44336",
          borderRadius: 0,
        },
      }}
    >
      {data &&
        data.map((banner, i) => (
          <Item key={i} banner={banner} desktop={desktop} />
        ))}
    </Carousel>
  );
};

function Item(props) {
  const { banner, desktop } = props;
  console.log("desktop", banner.desktop);

  const classes = useStyles();
  return (
    <Paper className={classes.main_container}>
      <Box className={classes.image_container}>
        {desktop == true ? (
          <Image
            className={classes.carousel_img}
            // component="img"
            // src="/images/static/man-choosed-motorcycles-moto-shop-guy-black-jacket-man-helmet.webp"
            src={banner.desktop}
            srcSet={`${banner.desktop} 1440w`}
            sizes="(max-width: 959px) 300px, (min-width:960px) 800px"
            layout="fill"
            objectFit="cover"
          />
        ) : (
          <Image
            className={classes.carousel_img}
            // component="img"
            // src="/images/static/man-choosed-motorcycles-moto-shop-guy-black-jacket-man-helmet.webp"
            src={banner.mobile}
            srcSet={`${banner.mobile}`}
            sizes="(max-width: 959px) 300px, (min-width:960px) 800px"
            layout="fill"
            objectFit="cover"
          />
        )}
        {/* <Box className={classes.content}>                    
                    <Box display='flex' justifyContent='center' alignItems='flex-end' color='white'>
                        <Image
                            src="/images/logos/isotipo_white.svg"
                            width={137}
                            height={104}
                        />
                    </Box>
                    <Box display='flex' justifyContent='center' alignItems='flex-start' color='white' className={classes.subtitle}>
                        Vende tu moto en minutos
                    </Box>
                </Box> */}
        <Box className={classes.gradient}></Box>
      </Box>
    </Paper>
  );
}

// export default React.memo(Carrusel);
export default memo(Carrusel);
