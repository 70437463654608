import dynamic from "next/dynamic";

const DynamicBottomNavigation = dynamic(() =>
  import("@material-ui/core/BottomNavigation")
);
const DynamicBottomNavigationAction = dynamic(() =>
  import("@material-ui/core/BottomNavigationAction")
);
const DynamicFooter = dynamic(() => import("../components/Footer/Footer"));

import { Box, Hidden } from "@material-ui/core";

import { makeStyles, useTheme, withTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
// import axios from "axios";
import Head from "next/head";
import Image from "next/image";
import Carrusel from "../components/Carousel/Carousel";
// import Footer from "../components/Footer/Footer";
// Components
import Header from "../components/Header/Header";
import axios from "axios";
// import useSWR from "swr";

const fetcher = (url) => axios.get(url).then((res) => res.data);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    fontSize: "34px",
  },
  image_container: {
    width: "100%",
    minHeight: "250px",
    display: "flex",
    justifyContent: "flex-end",
    margin: "3rem 0",
    position: "relative",
    "& div": {
      maxWidth: "80%",
      minWidth: "85%",
      position: "relative !important",
      height: "350px",
      [theme.breakpoints.up("sm")]: {
        width: "55%",
        height: "400px",
        minWidth: "55%",
      },
      [theme.breakpoints.up("xl")]: {
        width: "44%",
      },
    },
  },
  text_primary: {
    color: theme.palette.primary.main,
  },
  box_title: {
    padding: "2rem",
    color: "#2a434e",
    fontSize: "34px",
    "& > h5": {
      fontSize: "34px",
    },
  },
  list: {
    width: "100%",
    padding: "2rem",
    display: "grid",
    gridTemplateColumns: "2fr 10fr",
    gridTemplateRows: "repeat(3,1fr)",
    gridGap: "3rem 0",
  },
  item: {
    width: "33px",
    height: "33px",
    background: theme.palette.primary.main,
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    fontSize: "1rem",
    boxShadow: "2px 2px 4px rgba(6,6,6,0.5)",
    alignSelf: "center",
  },
  item_label: {
    fontSize: "18px",
    color: "#2a434e",
  },
  img_full: {
    width: "100%",
    minHeight: "580px",
    position: "relative",
  },
  img_full2: {
    width: "100%",
    minHeight: "250px",
    display: "flex",
    justifyContent: "flex-start",
    margin: "3rem 0",
  },
  list2: {
    display: "grid",
    gridTemplateColumns: "100%",
    gridTemplateRows: "repeat(3, 1fr)",
    gridGap: "0.5rem",
    padding: "1rem 2rem 2rem 2rem",
  },
  list2_title: {
    fontSize: "22px",
  },
  list2_body: {
    fontSize: "16px",
  },
  icon_cont: {
    width: "45px",
    height: "50px",
  },
  // Desktop styles
  desktop_title: {
    fontSize: "34px",
    margin: "0 auto",
  },
  desktop_text_primary: {
    fontSize: "34px",
    color: theme.palette.primary.main,
  },
  left: {
    width: "40%",
  },
  desktop_image_container: {
    maxWidth: "50%",
    minWidth: "30%",
    display: "flex",
    justifyContent: "flex-end",
    position: "relative",
    minHeight: "500px",
    width: "45%",
    [theme.breakpoints.up("lg")]: {
      width: "33%",
    },
    [theme.breakpoints.up("xl")]: {
      width: "30%",
    },
  },
  full_container_desktop: {
    width: "100%",
    position: "relative",
    minHeight: "470px",
    [theme.breakpoints.up("md")]: {
      minHeight: "620px",
      margin: "0",
    },
    [theme.breakpoints.up("lg")]: {
      minHeight: "850px",
      margin: "5% auto",
      "& img": {
        objectFit: "fill !important",
      },
    },
  },
  left_container: {
    maxWidth: "80%",
    minWidth: "30%",
    position: "relative",
    height: "470px",
    [theme.breakpoints.up("lg")]: {
      width: "55%",
    },
    [theme.breakpoints.up("xl")]: {
      width: "44%",
    },
  },
  desktop_grid: {
    display: " grid",
    gridTemplateColumns: " 1fr 1fr",
    gridTemplateRows: " 1fr 1fr",
    margin: " 3rem",
    gridGap: " 1rem",
  },
  navigation: {
    width: "100%",
    position: "fixed",
    bottom: "0",
    fontSize: "16px",
    color: "#2a434e",
    boxShadow: "0 4px 11px 2px rgba(0, 0, 0, 0.29)",
    display: "flex",
    justifyContent: "space-around",
  },
  text: {
    color: "#f44336",
    fontWeight: "700",
    textTransform: "uppercase",
    "&:disabled": {
      color: "#ccc",
    },
  },
}));

// export async function getStaticProps(context) {
//   let banners = [];

//   await axios
//     .get(`${process.env.HOST}/home-banners`)
//     .then((response) => {
//       // console.log('Banners:::::::::::::::::',response);

//       response.data.map((banner) => {
//         let ban = {
//           mobile:
//             banner.media[0].width < banner.media[1].width
//               ? banner.media[0].url
//               : banner.media[1].url,
//           desktop:
//             banner.media[0].width < banner.media[1].width
//               ? banner.media[1].url
//               : banner.media[0].url,
//         };
//         banners.push(ban);
//       });
//     })
//     .catch((err) => {
//       console.log("error getting banners", err);
//     });

//   return {
//     props: {
//       banners,
//     },
//   };
// }

function Home() {
  // const { data, error } = useSWR("/api/getBanners", fetcher);
  // if (isLoading) return <div>cargando</div>;
  // console.log("BANNERS-------------->>:", data, error);
  // if (error == undefined || data == undefined) return null;
  // if (error) return <div>Error</div>;

  const classes = useStyles();
  const theme = useTheme();
  // const { banners } = data || [];

  return (
    <div>
      <Head>
        <title>Motofácil</title>
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <Header></Header>
      {/* Desktop */}
      <Hidden smDown implementation="css">
        <Carrusel desktop={true}></Carrusel>
        <Box my={5} display="flex" justifyContent="center">
          <Typography
            variant="h1"
            color="primary"
            className={classes.desktop_title}
          >
            Vender tu moto nunca fue tan fácil
          </Typography>
        </Box>

        <Box my={4} display="flex" justifyContent="space-between">
          <Box ml={10} my={5} className={classes.left}>
            <Box component="div" p={2} className={classes.box_title}>
              <Typography variant="h5" color="initial">
                Compramos{" "}
                <span className={classes.desktop_text_primary}>
                  1 de cada 3&nbsp;
                </span>{" "}
                motos que inspeccionamos
              </Typography>
            </Box>
            <Box className={classes.list}>
              <Box className={classes.item}>1</Box>
              <Box className={classes.item_label}>
                Te ofrecemos la mejor oferta por tu moto, no podrás rechazarla
              </Box>
              <Box className={classes.item}>2</Box>
              <Box className={classes.item_label}>
                Tu eliges el día y la hora para realizar la inspección
              </Box>
              <Box className={classes.item}>3</Box>
              <Box className={classes.item_label}>
                Un agente Moto Fácil te acompañará durante todo el proceso
              </Box>
            </Box>
          </Box>
          <Box className={classes.desktop_image_container}>
            <Image
              src="/images/static/home/img2.webp"
              layout="fill"
              objectFit="contain"
            />
          </Box>
        </Box>
        <Box my={3} className={classes.full_container_desktop}>
          <Image
            src="/images/static/home/img-2-x_2021-04-03/img-2-x.webp"
            layout="fill"
            objectFit="contain"
          />
        </Box>
        <Box mx={5} display="flex" justifyContent="flex-start" width="70%">
          <Box component="div" p={2} className={classes.box_title}>
            <Typography variant="h5" color="initial">
              Vende rápido y compra el mejor precio...{" "}
              <span className={classes.desktop_text_primary}>
                ¿Estás listo para estrenar tu moto?
              </span>
            </Typography>
          </Box>
        </Box>

        <Box my={3} className={classes.left_container}>
          <Image
            src="/images/static/home/leftBanner/img-2-x.webp"
            layout="fill"
            objectFit="contain"
          />
        </Box>

        <Box className={classes.desktop_grid}>
          <Box component="div" className={classes.list2}>
            <Box className={classes.icon_cont}>
              <Image
                src="/images/static/home/icon1.webp"
                width="45"
                height="50"
              />
            </Box>
            <Box className={classes.list2_title}>
              El proceso de venta es{" "}
              <span className={classes.text_primary}>completamente seguro</span>{" "}
            </Box>
            <Box className={classes.list2_body}>
              Solo cuéntanos el estado general de tu moto, no te solicitaremos
              ningún tipo de pago
            </Box>
          </Box>
          <Box component="div" className={classes.list2}>
            <Box className={classes.icon_cont}>
              <Image
                src="/images/static/home/icon2.webp"
                width="35"
                height="50"
              />
            </Box>
            <Box className={classes.list2_title}>
              Vende sin intermediarios y con garantía{" "}
              <span className={classes.text_primary}>Moto Fácil</span>{" "}
            </Box>
            <Box className={classes.list2_body}>
              Deja de enseñar tu moto a desconocidos y obtén un precio justo
            </Box>
          </Box>
          <Box component="div" className={classes.list2}>
            <Box className={classes.icon_cont}>
              <Image
                src="/images/static/home/icon3.webp"
                width="50"
                height="50"
              />
            </Box>
            <Box className={classes.list2_title}>
              Compra tu <span className={classes.text_primary}>moto usada</span>{" "}
              al mejor precio del mercado
            </Box>
            <Box className={classes.list2_body}>
              Contamos con una gran variedad de motos seminuevas, elige una y
              conócela en nuestros centros Moto Fácil
            </Box>
          </Box>
          <Box component="div" className={classes.list2}>
            <Box className={classes.icon_cont}>
              <Image
                src="/images/static/home/icon4.webp"
                width="50"
                height="50"
              />
            </Box>
            <Box className={classes.list2_title}>
              Inspeccionamos tu moto solo en nuestros centros autorizados{" "}
              <span className={classes.text_primary}>Moto Fácil</span>{" "}
            </Box>
            <Box className={classes.list2_body}>
              Nuestra prioridad es ofrecerte una buena oferta, con
              transparencia, seguridad y el mejor seguimiento en el proceso de
              compra y venta
            </Box>
          </Box>
        </Box>

        <DynamicFooter></DynamicFooter>
      </Hidden>

      {/* Mobile */}
      <Hidden mdUp>
        <Carrusel desktop={false}></Carrusel>
        <Box my={2} mx={3}>
          <Typography variant="h1" color="primary" className={classes.title}>
            Vender tu moto nunca fue tan fácil
          </Typography>
        </Box>
        <Box className={classes.image_container}>
          <Image
            src="/images/static/home/img2.webp"
            layout="fill"
            objectFit="contain"
          />
        </Box>
        <Box component="div" p={2} className={classes.box_title}>
          <Typography variant="h5" color="initial">
            Compramos{" "}
            <span className={classes.text_primary}>1 de cada 3&nbsp;</span>{" "}
            motos que inspeccionamos
          </Typography>
        </Box>
        <Box className={classes.list}>
          <Box className={classes.item}>1</Box>
          <Box className={classes.item_label}>
            Te ofrecemos la mejor oferta por tu moto, no podrás rechazarla
          </Box>
          <Box className={classes.item}>2</Box>
          <Box className={classes.item_label}>
            Tu eliges el día y la hora para realizar la inspección
          </Box>
          <Box className={classes.item}>3</Box>
          <Box className={classes.item_label}>
            Un agente Moto Fácil te acompañará durante todo el proceso
          </Box>
        </Box>
        <Box my={2} className={classes.img_full}>
          <Image
            src="/images/static/home/img-2-x.webp"
            layout="fill"
            objectFit="cover"
          />
        </Box>
        <Box component="div" p={2} className={classes.box_title}>
          <Typography variant="h5" color="initial">
            Vende rápido y compra el mejor precio...{" "}
            <span className={classes.desktop_text_primary}>
              ¿Estás listo para estrenar tu moto?
            </span>
          </Typography>
        </Box>
        <Box my={2} className={classes.img_full2}>
          <Image src="/images/static/home/img3.webp" width="300" height="200" />
        </Box>

        {/* offer list section */}
        <Box>
          <Box component="div" className={classes.list2}>
            <Box className={classes.icon_cont}>
              <Image
                src="/images/static/home/icon1.webp"
                width="45"
                height="50"
              />
            </Box>
            <Box className={classes.list2_title}>
              El proceso de venta es{" "}
              <span className={classes.text_primary}>completamente seguro</span>{" "}
            </Box>
            <Box className={classes.list2_body}>
              Solo cuéntanos el estado general de tu moto, no te solicitaremos
              ningún tipo de pago
            </Box>
          </Box>
          <Box component="div" className={classes.list2}>
            <Box className={classes.icon_cont}>
              <Image
                src="/images/static/home/icon2.webp"
                width="35"
                height="50"
              />
            </Box>
            <Box className={classes.list2_title}>
              Vende sin intermediarios y con garantía{" "}
              <span className={classes.text_primary}>Moto Fácil</span>{" "}
            </Box>
            <Box className={classes.list2_body}>
              Deja de enseñar tu moto a desconocidos y obtén un precio justo
            </Box>
          </Box>
          <Box component="div" className={classes.list2}>
            <Box className={classes.icon_cont}>
              <Image
                src="/images/static/home/icon3.webp"
                width="50"
                height="50"
              />
            </Box>
            <Box className={classes.list2_title}>
              Compra tu <span className={classes.text_primary}>moto usada</span>{" "}
              al mejor precio del mercado
            </Box>
            <Box className={classes.list2_body}>
              Contamos con una gran variedad de motos seminuevas, elige una y
              conócela en nuestros centros Moto Fácil
            </Box>
          </Box>
          <Box component="div" className={classes.list2}>
            <Box className={classes.icon_cont}>
              <Image
                src="/images/static/home/icon4.webp"
                width="50"
                height="50"
              />
            </Box>
            <Box className={classes.list2_title}>
              Inspeccionamos tu moto solo en nuestros centros autorizados{" "}
              <span className={classes.text_primary}>Moto Fácil</span>{" "}
            </Box>
            <Box className={classes.list2_body}>
              Nuestra prioridad es ofrecerte una buena oferta, con
              transparencia, seguridad y el mejor seguimiento en el proceso de
              compra y venta
            </Box>
          </Box>
        </Box>
        <Box my={4}>
          <DynamicFooter></DynamicFooter>
        </Box>
        {/* <BottomNavHome left={'Comprar una moto'} right={'Vender una moto'} leftValue={'comprarmoto'} rightValue={'vende-tu-moto'}/> */}
        <DynamicBottomNavigation showLabels className={classes.navigation}>
          <DynamicBottomNavigationAction
            className={classes.text}
            label={"Comprar una moto"}
            href="/comprarmoto"
          />
          <DynamicBottomNavigationAction
            className={classes.text}
            label={"Vender una moto"}
            href="/vende-tu-moto/contacto"
            type="submit"
          />
        </DynamicBottomNavigation>
      </Hidden>
    </div>
  );
}

export default withTheme(Home);
