import React from "react";
import Image from "next/image";
import Link from "next/link";
import dynamic from "next/dynamic";
// Components
import { Box, Button } from "@material-ui/core";

// Styles
import { makeStyles } from "@material-ui/core/styles";

const DynamicAppBar = dynamic(() => import("@material-ui/core/AppBar"));
const DynamicToolbar = dynamic(() => import("@material-ui/core/Toolbar"));

// import DynamicAppBar from "@material-ui/core/DynamicAppBar";
// import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Hidden from "@material-ui/core/Hidden";
import clsx from "clsx";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import CloseIcon from "@material-ui/icons/Close";
import { useAuth } from "hooks/useAuth";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflow: "hidden",
  },
  desktop: {
    height: "5rem",
    minHeight: "83px",
    textAlign: "center",
    color: "white",
    background: theme.palette.primary.main,
  },
  iso: {
    width: "100%",
    height: "100%",
    position: "relative",
  },
  titles: {
    fontSize: "16px",
    // color: '#2a434e',
    color: "white",
    fontFamily: "Quicksand",
  },
  subtitles: {
    // color: '#f44336',
    color: "white",
    fontSize: "1rem",
    fontFamily: "Quicksand",
    fontWeight: "700",
  },

  // Mobile Header
  appBar: {
    color: "#fff",
    background: theme.palette.primary.main,
  },
  image: {
    width: "30%",
    position: "relative",
    height: "50px",
  },
  menu_header: {
    display: "flex",
    justifyContent: "space-between",
  },
  image_menu: {
    width: "50%",
    position: "relative",
    height: "50px",
  },
  // menuButton: {
  // 	marginRight: theme.spacing(2),
  // },
  mobileToolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  login_text: {
    color: theme.palette.primary.main,
    fontWeight: 900,
  },
  primary_bg: {
    background: theme.palette.primary.main,
    // background: 'white'
  },
  menu_container: {
    display: "grid",
    gridTemplateRows: "50vh 15vh 1px 35vh",
    "& .MuiListItem-root": {
      paddingTop: "4px",
      paddingBottom: "4px",
    },
  },

  bottom_menu: {
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "column",
  },
  logo: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  click: {
    cursor: "pointer",
  },
  truncate: {
    width: "105px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    cursor: "pointer",
  },
}));

const Header = () => {
  const auth = useAuth();
  const anchor = "right";
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(
        classes.list,
        {
          [classes.fullList]: anchor === "top" || anchor === "bottom",
        },
        classes.menu_container
      )}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem className={classes.menu_header}>
          <Box
            className={classes.image_menu}
            display="flex"
            justifyContent="flex-start"
          >
            <Link href="/" passHref>
              <Image
                component="img"
                src="/images/logos/main-logo.svg"
                layout="fill"
                objectFit="contain"
              />
            </Link>
          </Box>
          <ListItemIcon>
            <CloseIcon color="primary" />
          </ListItemIcon>
        </ListItem>
        {auth.user ? (
          <ListItem button>
            <ListItemText
              classes={{
                primary: classes.login_text,
              }}
            >
              <Link href="/citas" passHref style={{ fontWeight: "bold" }}>
                <a className={classes.click}>Hola {auth.user.name}</a>
              </Link>
            </ListItemText>
          </ListItem>
        ) : null}
        {auth.user ? (
          <ListItem button>
            <ListItemText
              classes={{
                primary: classes.login_texto,
              }}
            >
              <Link href="/mi-cuenta" passHref>
                <ListItemText primary="Mi Cuenta" />
              </Link>
            </ListItemText>
          </ListItem>
        ) : null}
        <ListItem button>
          <ListItemText>
            <Link href="/vende-tu-moto/contacto" passHref>
              <a>Vender una moto</a>
            </Link>
          </ListItemText>
        </ListItem>
        <ListItem button>
          <ListItemText>
            <Link href="/comprarmoto" passHref>
              <a>Comprar una moto</a>
            </Link>
          </ListItemText>
        </ListItem>
        <ListItem button>
          <ListItemText>
            <Link href="/acerca-de" passHref>
              <a>Acerca de motofácil</a>
            </Link>
          </ListItemText>
        </ListItem>
        {!auth.user ? (
          <ListItem button>
            <ListItemText
              classes={{
                primary: classes.login_text,
              }}
            >
              <Link href="/login" passHref>
                <a>Iniciar sesión / Registrarme</a>
              </Link>
            </ListItemText>
          </ListItem>
        ) : null}
      </List>
      <Box></Box>
      <Divider className={classes.primary_bg} />

      <List bottom className={classes.bottom_menu}>
        <ListItem button>
          <ListItemText>
            <Link href="/contacto" passHref>
              <a>Contacto</a>
            </Link>
          </ListItemText>
        </ListItem>
        <ListItem button>
          <ListItemText>
            <Link href="/faqs" passHref>
              <a>Preguntas frecuentes</a>
            </Link>
          </ListItemText>
        </ListItem>
        <ListItem button>
          <ListItemText>
            <Link href="/terminosycondiciones" passHref>
              <a>Términos y condiciones</a>
            </Link>
          </ListItemText>
        </ListItem>
        <ListItem minWidth="135px" display="flex" justifyContent="space-evenly">
          <a style={{ fontSize: "1rem" }}>Síguenos en:</a>
          <Link href="https://www.facebook.com/motofacilmx">
            <span>
              <Image
                src="/images/static/atom-icon-medium-facebook_2021-06-09/atom-icon-medium-facebook.webp"
                width="20px"
                height="20px"
              ></Image>
            </span>
          </Link>
          <Link href="https://www.instagram.com/motofacil.mx/">
            <span>
              <Image
                src="/images/static/atom-icon-medium-instagram_2021-06-09/atom-icon-medium-instagram.webp"
                width="20px"
                height="20px"
              ></Image>
            </span>
          </Link>
        </ListItem>
        {/* TODO: method for close session */}
        {auth.user ? (
          <ListItem button>
            <ListItemText
              style={{ fontWeight: "bold" }}
              primary="Cerrar Sesión"
              onClick={() => auth.signOut()}
            />
          </ListItem>
        ) : null}
      </List>
    </div>
  );

  return (
    <>
      {/* Desktop Header */}
      <Box
        component="header"
        className={classes.desktop}
        display="flex"
        justifyContent="space-between"
        px="3rem"
        display={{ xs: "none", sm: "none", md: "flex" }}
      >
        <Box component="div" flexGrow={2.5} display="flex">
          <Box
            className={classes.iso}
            display="flex"
            justifyContent="flex-end"
            flexGrow={1}
          >
            <Link href="/" passHref>
              <Image
                className={classes.logo}
                flexGrow={1}
                component="img"
                src="/images/logos/main-logo-white.svg"
                layout="fill"
                objectFit="contain"
              />
            </Link>
          </Box>
        </Box>

        <Box
          flexGrow={4}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Box display="flex" justifyContent="space-evenly" width="80%">
            <Box className={classes.titles} component="span">
              <Link href="/vende-tu-moto/contacto" passHref>
                <a>Vender una moto</a>
              </Link>
            </Box>
            <Box className={classes.titles} component="span">
              <Link href="/comprarmoto">Comprar una moto</Link>
            </Box>
            <Box className={classes.titles} component="span">
              <Link href="/acerca-de">Acerca de motofácil</Link>
            </Box>
          </Box>
        </Box>
        <Box
          flexGrow={1}
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Box display="flex" justifyContent="flex-end">
            {auth.user ? (
              <Box
                width="100%"
                display="flex"
                flexDirection="row"
                style={{ fontWeight: "bold" }}
              >
                <Link href="/mi-cuenta">
                  <Box mr={2} className={classes.truncate}>
                    Hola {auth.user.name}
                  </Box>
                </Link>
                <Box
                  className={classes.click}
                  onClick={() => auth.signOut()}
                  style={{ fontWeight: "bold" }}
                >
                  Cerrar Sesión
                </Box>
              </Box>
            ) : (
              <Box className={classes.subtitles} component="span">
                <Link href="/login" passHref>
                  Iniciar sesión / Registrarme
                </Link>
              </Box>
            )}
          </Box>
        </Box>
      </Box>

      {/* Mobile Header */}
      <Hidden mdUp>
        <DynamicAppBar
          position="static"
          className={classes.appBar}
          // display="flex"
          display={{ sm: "flex", md: "flex", lg: "none" }}
        >
          <DynamicToolbar className={classes.mobileToolbar}>
            <Box
              className={classes.image}
              display="flex"
              justifyContent="flex-start"
            >
              <Link href="/" passHref>
                <Image
                  // flexGrow={1}
                  component="img"
                  src="/images/logos/white-logo.svg"
                  layout="fill"
                  objectFit="contain"
                />
              </Link>
            </Box>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer(anchor, true)}
            >
              <MenuIcon />
            </IconButton>
          </DynamicToolbar>
        </DynamicAppBar>
        <Drawer
          anchor={anchor}
          open={state[anchor]}
          onClose={toggleDrawer(anchor, false)}
        >
          {list(anchor)}
        </Drawer>
      </Hidden>
    </>
  );
};

export default Header;
